import { debug } from './debugging';
import { COUNTRY_SELECTOR_COOKIE_NAME } from '../types';

/**
 * Get the user's current country code
 *
 * @returns two letter ISO 3166-1 country code
 */
export const getCountryCode = (): string => {
  const cookieCountryCode = (getCookie(COUNTRY_SELECTOR_COOKIE_NAME) || '').toLocaleLowerCase();
  debug(`getCountryCode(): cookieCountryCode=${cookieCountryCode}`);

  let countryCode: string = '';

  if (cookieCountryCode) {
    countryCode = cookieCountryCode;
  } else {
    // Fallback: if country selector cookie was not found, use browser's locale for that
    const { locale } = new Intl.DateTimeFormat().resolvedOptions();
    debug(`getCountryCode(): fallback browser locale=${locale}`);
    const localeSplit = locale.split('-');
    const localePart2 = localeSplit[2] || localeSplit[1] || localeSplit[0];
    countryCode = localePart2 ? localePart2.toLowerCase() : '';
    debug('getCountryCode(): fallback country code', countryCode || 'not found!');
  }

  debug(`getCountryCode(): return countryCode=${countryCode}`);

  return countryCode;
};

/**
 * Returns all cookies
 *
 * @returns hash map of all cookies
 */
function getAllCookies() {
  const cookies: { [key: string]: string } = {};

  if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
    document.cookie.split(';').forEach((el) => {
      const [k, ...v] = el.split('=');
      cookies[k.trim()] = v.join('=');
    });
  }

  return cookies;
}

/**
 * Get a cookie value by it's cookie's name
 *
 * @param name name/key of the cookie
 * @returns value of the cookie
 */
function getCookie(name: string): string | undefined {
  return getAllCookies()[name];
}
