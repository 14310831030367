import React from 'react';
import styled from 'styled-components';
import { Headline, Text, TextButton } from '@oneaudi/unified-web-components';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';
import {
  SYS_BREAKPOINT_MD,
  SYS_SPACE_RELATIVE_2_XL,
  SYS_SPACE_RELATIVE_LG,
  SYS_SPACE_RELATIVE_SM,
  SYS_SPACE_RELATIVE_XL,
} from '@oneaudi/unified-web-common';
import { ArrowButton } from './ArrowButton';

import { enableHTMLEntities } from '../../utils/enableHTMLEntities';
import { LegalInfo } from '../LegalInfo/LegalInfo';
import { AppContent } from '../../types';

const StyledImageContainer = styled.div`
  img {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
  }
`;

const StyledTextContainer = styled.div`
  padding-top: ${SYS_SPACE_RELATIVE_LG};

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    padding-top: 0;
    place-self: center start;
  }
`;

const StyledBasicTeaserLike = styled.div<{ addBottomSpacing: boolean }>`
  @media (hover: hover) {
    pointer-events: none;
  }

  @media (hover: none) {
    pointer-events: all;
  }

  .audi-footnote-anchor__text {
    pointer-events: all;
  }

  .teaser-textbutton {
    display: none;
  }

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    display: grid;
    grid-gap: ${SYS_SPACE_RELATIVE_2_XL};
    grid-template-columns: repeat(2, 1fr);

    .teaser-textbutton {
      margin-top: ${SYS_SPACE_RELATIVE_XL};
      margin-bottom: ${(props) => (props.addBottomSpacing ? SYS_SPACE_RELATIVE_XL : 0)};
      display: inline-flex;
      cursor: pointer;
      pointer-events: all;
    }
  }
`;

const StyledHeadline = styled(Headline)`
  padding-bottom: ${SYS_SPACE_RELATIVE_SM};

  .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 13px !important;
  }
`;

const StyledCopy = styled(Text)`
  .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 11px !important;
  }

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    padding-bottom: ${SYS_SPACE_RELATIVE_SM};
  }
`;

export interface BasicTeaserLikeProps extends AppContent {
  onTeaserClick(this: void): void;
}

export const BasicTeaserLike: React.FC<BasicTeaserLikeProps> = (props) => {
  const {
    image,
    imageAltText,
    headline,
    copy,
    linkLabel,
    linkUrl,
    consumptionAndEmissions,
    disclaimers,
    onTeaserClick,
  } = props;

  const hasLegalInfo = Boolean(consumptionAndEmissions?.length) && Boolean(disclaimers?.length);

  const handleTeaserClickEvent = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as Element;

    if (target.className !== 'audi-j-footnote-reference') {
      e.preventDefault();
      if (typeof window !== 'undefined') {
        onTeaserClick();
        window.location.href = linkUrl || '';
      }
    }
  };

  return (
    <StyledBasicTeaserLike onClick={handleTeaserClickEvent} addBottomSpacing={hasLegalInfo}>
      {image && (
        <StyledImageContainer>
          <UeElement type="media" property="image" label="Image">
            {(ueProps) => <img loading="lazy" src={image} alt={imageAltText || ''} {...ueProps} />}
          </UeElement>
        </StyledImageContainer>
      )}
      <StyledTextContainer>
        {headline && (
          <StyledHeadline tag="h3" variant={{ order: '3', type: 'headline', weight: 'normal' }}>
            <UeElement type="text" property="headline" label="Headline">
              {renderTextWithFootnotesReferencesV2(enableHTMLEntities(headline))}
            </UeElement>
          </StyledHeadline>
        )}
        {copy && (
          <StyledCopy variant={{ order: '1', style: 'normal' }}>
            <UeElement type="text" property="copy" label="Copy">
              {renderTextWithFootnotesReferencesV2(enableHTMLEntities(copy))}
            </UeElement>
          </StyledCopy>
        )}
        {linkUrl && linkLabel && (
          <>
            <ArrowButton
              teaserLink={linkUrl}
              linkLabel={linkLabel}
              smallScreensOnly
              onClick={onTeaserClick}
              addBottomSpacing={hasLegalInfo}
            />
            <TextButton
              className="teaser-textbutton"
              href={linkUrl}
              variant="primary"
              onClick={onTeaserClick}
            >
              <UeElement type="text" property="linkLabel" label="Link Label">
                {linkLabel}
              </UeElement>
            </TextButton>
          </>
        )}
        <LegalInfo consumptionAndEmissions={consumptionAndEmissions} disclaimers={disclaimers} />
      </StyledTextContainer>
    </StyledBasicTeaserLike>
  );
};
