import { CountryLinkCF } from '../types';
import { debug } from './debugging';

/**
 * Retrieves a link URL from a bulk CVS link list or discrete array list by country code.
 *
 * @param bulkLinks string in CVS format containing a pair of country code and associated url in each line, eg. fr,https://url
 * @param discreteLinks array of CountryLinkCF content fragments, each item holds countryCode and linkUrl fields
 * @param countryCode country code to get a match for
 * @returns
 */
export const getLinkUrl = (
  bulkLinks: string,
  discreteLinks: CountryLinkCF[],
  countryCode: string,
) => {
  let linkUrl = getLinkUrlFromCF(discreteLinks, countryCode);

  if (!linkUrl) {
    linkUrl = getLinkUrlFromCSV(bulkLinks, countryCode);
  }

  return linkUrl;
};

export function getLinkUrlFromCSV(bulkLinks: string, countryCode: string): string {
  let linkUrl = '';
  const cvsLines = bulkLinks.split('\n');

  cvsLines.forEach((cvsLine) => {
    const cvsLineParts = cvsLine.split(',');
    const [currentCountryCode, currentLinkUrl] = cvsLineParts;

    if (countryCode && currentCountryCode.trim().toLowerCase() === countryCode.toLowerCase()) {
      linkUrl = currentLinkUrl;
    }
  });

  debug(`getLinkUrlFromCSV: ${linkUrl || 'No URL found!'} (countryCode=${countryCode})`);

  return linkUrl;
}

export function getLinkUrlFromCF(discreteLinks: CountryLinkCF[], countryCode: string): string {
  let linkUrl = '';

  discreteLinks.forEach((cf) => {
    if (
      countryCode &&
      cf.fields &&
      cf.fields.linkUrl &&
      cf.fields.countryCode &&
      cf.fields.countryCode.toLowerCase() === countryCode.toLowerCase()
    ) {
      linkUrl = cf.fields.linkUrl;
    }
  });

  debug(`getLinkUrlFromCF: ${linkUrl || 'No URL found!'} (countryCode=${countryCode})`);

  return linkUrl;
}
