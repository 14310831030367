import * as React from 'react';
import styled from 'styled-components';
import { TextLink, Icon } from '@oneaudi/unified-web-components';
import {
  CMP_COLOR_NAVIGATION_TEXT_LINK_FOREGROUND_DEFAULT_DARK,
  CMP_COLOR_NAVIGATION_TEXT_LINK_FOREGROUND_DEFAULT_LIGHT,
  SEM_COLOR_ACTION_SECONDARY_FOREGROUND_TEXT_HOVER_DARK,
  SEM_COLOR_ACTION_SECONDARY_FOREGROUND_TEXT_HOVER_LIGHT,
  SYS_BREAKPOINT_MD,
  SYS_SIZE_FIXED_100,
  SYS_SIZE_FIXED_250,
  SYS_SPACE_RELATIVE_LG,
} from '@oneaudi/unified-web-common';

const MOVE_SM = '300ms cubic-bezier(0.75, 0.02, 0.5, 1)';

interface ArrowButtonProps {
  teaserLink: string;
  onClick: () => void;
  linkLabel: string;
  smallScreensOnly: boolean;
  addBottomSpacing: boolean;
}

export const ArrowButton: React.FC<ArrowButtonProps> = (props) => {
  const { teaserLink, onClick, linkLabel, smallScreensOnly, addBottomSpacing } = props;

  return (
    <StyledTextLink smallScreensOnly={smallScreensOnly} addBottomSpacing={addBottomSpacing}>
      <TextLink
        variant="standalone"
        size="md"
        className="teaser-textlink"
        href={teaserLink}
        onClick={onClick}
      >
        {linkLabel}
      </TextLink>
      <Icon className="arrow" name="forward" size="s" />
    </StyledTextLink>
  );
};

const StyledTextLink = styled.div<{ smallScreensOnly: boolean; addBottomSpacing: boolean }>`
  display: inline-flex;
  align-items: center;
  margin-top: ${SYS_SPACE_RELATIVE_LG};
  margin-bottom: ${(props) => (props.addBottomSpacing ? SYS_SPACE_RELATIVE_LG : 0)};

  .teaser-textlink {
    display: inline-flex;
    pointer-events: all;
    cursor: pointer;
  }

  /* only trigger arrow animation if text is hovered */
  .teaser-textlink:hover + .arrow {
    padding-inline-start: ${SYS_SIZE_FIXED_250};
    color: ${({ theme }) =>
      theme.name === 'dark'
        ? SEM_COLOR_ACTION_SECONDARY_FOREGROUND_TEXT_HOVER_DARK
        : SEM_COLOR_ACTION_SECONDARY_FOREGROUND_TEXT_HOVER_LIGHT};
  }

  .arrow {
    color: ${({ theme }) =>
      theme.name === 'dark'
        ? CMP_COLOR_NAVIGATION_TEXT_LINK_FOREGROUND_DEFAULT_DARK
        : CMP_COLOR_NAVIGATION_TEXT_LINK_FOREGROUND_DEFAULT_LIGHT};
    padding-inline-start: ${SYS_SIZE_FIXED_100};
    transition: ${MOVE_SM};
  }

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    display: ${(props) => (props.smallScreensOnly ? 'none' : 'inline-flex')};
  }
`;
