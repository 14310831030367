/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import styled from 'styled-components';
import { Text } from '@oneaudi/unified-web-components';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { SYS_SPACE_RELATIVE_XS } from '@oneaudi/unified-web-common';

const DisclaimersContainer = styled.div``;

export interface DisclaimersProps {
  disclaimers?: string[];
}

const StyledDisclaimer = styled(Text)`
  margin-block-end: ${SYS_SPACE_RELATIVE_XS};
`;

export const Disclaimers: React.FC<DisclaimersProps> = ({ disclaimers }) => {
  if (!disclaimers || !disclaimers.length) return null;

  return (
    <DisclaimersContainer>
      {disclaimers?.map((disclaimer, i) => (
        <StyledDisclaimer key={`disclaimer${i}`} variant={{ order: '2', style: 'normal' }}>
          {renderTextWithFootnotesReferencesV2(disclaimer)}
        </StyledDisclaimer>
      ))}
    </DisclaimersContainer>
  );
};

Disclaimers.displayName = 'Disclaimers';
