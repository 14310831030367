/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import type { ContentServiceV1 } from '@oneaudi/content-service';

import { CountryTeaser } from './components/CountryTeaser/CountryTeaser';
import { AppContent } from './types';
import { mapContent } from './utils/contentMapping';
import { getCountryCode } from './utils/getCountryCode';
import { debug } from './utils/debugging';

interface FeatureAppProps {
  contentService: ContentServiceV1;
}

const FeatureApp: React.FC<FeatureAppProps> = ({ contentService }: FeatureAppProps) => {
  const [countryCode, setCountryCode] = React.useState<string>('');
  const [content, setContent] = React.useState<AppContent | undefined>(
    mapContent(contentService, countryCode),
  );

  React.useEffect(() => {
    const newCountryCode = getCountryCode();
    debug(`>>> >>> setCountryCode to "${newCountryCode}" on initial mount`);

    setCountryCode(newCountryCode);
    setContent(mapContent(contentService, newCountryCode));
  }, []);

  React.useEffect(() => {
    const listener = () => {
      debug('>>> >>> setContent onContentChanged');
      setContent(mapContent(contentService, countryCode));
    };
    contentService.onContentChange(listener);

    return () => {
      contentService.removeOnContentChange(listener);
    };
  }, [contentService]);

  return content ? <CountryTeaser {...content} /> : null;
};

export default FeatureApp;
