import { Asset } from '@oneaudi/falcon-tools';

export type ThemeType = 'Dark' | 'Light';
export const COUNTRY_SELECTOR_COOKIE_NAME = 'countryselector';

export interface CountryLinkCF {
  __type: 'aem-headless';
  fields: {
    countryCode?: string;
    linkUrl?: string;
  };
}

export interface FalconContent {
  __type: 'aem-headless';
  fields: {
    image?: Asset;
    imageAltText?: string;
    headline?: string;
    copy?: string;
    linkLabel?: string;
    bulkLinks?: string;
    discreteLinks?: CountryLinkCF[];
    consumptionAndEmissions?: string[];
    disclaimers?: string[];
    theme?: ThemeType;
  };
}

export interface AppContent {
  image?: string;
  imageAltText?: string;
  headline?: string;
  copy?: string;
  linkLabel?: string;
  linkUrl?: string;
  consumptionAndEmissions?: string[];
  disclaimers?: string[];
  theme?: ThemeType;
}
