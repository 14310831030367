import type { ContentServiceV1 } from '@oneaudi/content-service';
import { InvalidContentError, isContentFragment } from '@oneaudi/falcon-tools';
import { AppContent, FalconContent } from '../types';
import { debug } from './debugging';
import { getLinkUrl } from './getLinkUrl';

export const INVALID_CONTENT_ERROR = 'Content is invalid.';

function isFalconContent(content: FalconContent): content is FalconContent {
  // Check here for all required fields and return true, otherwise false
  return Boolean(isContentFragment(content) && content.fields.headline && content.fields.copy);
}

export function mapContent(contentService: ContentServiceV1, countryCode: string): AppContent {
  const content = contentService.getContent() as FalconContent;

  if (!content || content.__type !== 'aem-headless' || !isFalconContent(content)) {
    // Throw `InvalidContentError` here if provided content
    // is not valid. This will display as a special error message
    // in the frontend on author environments.
    debug('InvalidContentError/Content is invalid.', content);
    throw new InvalidContentError(INVALID_CONTENT_ERROR);
  }

  // map CF content to internal structure
  return mapFalconContent(content, countryCode);
}

/**
 * Map FalconContent to AppContent
 *
 * @param content FalconContent
 * @returns AppContent
 */
export function mapFalconContent(content: FalconContent, countryCode: string): AppContent {
  debug('raw content', content);

  const mappedContent: AppContent = {
    theme: content.fields.theme || 'Light',
  };

  if (content.fields.image && content.fields.image.path) {
    mappedContent.image = content.fields.image.path;
  }

  if (content.fields.imageAltText) {
    mappedContent.imageAltText = content.fields.imageAltText;
  }

  if (content.fields.headline) {
    mappedContent.headline = content.fields.headline;
  }

  if (content.fields.copy) {
    mappedContent.copy = content.fields.copy;
  }

  if (content.fields.linkLabel) {
    mappedContent.linkLabel = content.fields.linkLabel;
  }

  if (
    content.fields.bulkLinks ||
    (content.fields.discreteLinks && content.fields.discreteLinks.length > 0)
  ) {
    mappedContent.linkUrl = getLinkUrl(
      content.fields.bulkLinks || '',
      content.fields.discreteLinks || [],
      countryCode,
    );
  }

  if (content.fields.consumptionAndEmissions && content.fields.consumptionAndEmissions.length > 0) {
    mappedContent.consumptionAndEmissions = content.fields.consumptionAndEmissions;
  }

  if (content.fields.disclaimers && content.fields.disclaimers.length > 0) {
    mappedContent.disclaimers = content.fields.disclaimers;
  }

  debug('mapped content', mappedContent);

  return mappedContent;
}
