import { useContext } from 'react';
import { Context } from '../Context';
import { AppContent } from '../types';

export interface Tracking {
  sendReadyEvent(): void;
  registerImpressionTracking(): void;
  sendClickEvent(this: void): void;
}

export function useTracking(
  ref: React.MutableRefObject<null>,
  appContent: AppContent,
  attributes?: {
    version?: string;
    implementer?: number;
    [key: string]: unknown;
  },
): Tracking {
  const { trackingService } = useContext(Context);
  const featureAppName = (typeof __FEATURE_APP_NAME__ === 'string' && __FEATURE_APP_NAME__) || '';
  const componentNameWithDashes = featureAppName.replace('fa-', '');
  const componentNameWithSpaces = componentNameWithDashes.replace(/-/g, ' ');
  const defaultComponentAdditions = {
    componentInfo: {
      // not accepted by tracking lib
      // componentID: 'bla',
    },
    attributes: {
      version: __FEATURE_APP_VERSION__,
      ...attributes,
    },
  };

  return {
    sendReadyEvent: () => {
      if (trackingService) {
        const readyEvent = {
          event: {
            attributes: {
              componentName: componentNameWithDashes,
              elementName: '',
              label: '',
              clickID: '',
              pos: '',
              targetURL: '',
              value: '',
            },
            eventInfo: {
              eventAction: 'feature_app_ready',
              eventName: `${componentNameWithSpaces} - feature app ready`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        trackingService.track(readyEvent);
      }
    },
    registerImpressionTracking: () => {
      if (trackingService && ref.current) {
        trackingService.registerImpressionTracking(ref.current, () => {
          return {
            event: {
              attributes: {
                componentName: componentNameWithDashes,
                elementName: '',
                label: '',
                clickID: '',
                pos: '',
                targetURL: '',
                value: removeCurlyBraces(appContent.headline || ''),
              },
              eventInfo: {
                eventAction: 'impression',
                eventName: `${componentNameWithSpaces} - impression`,
              },
            },
            componentUpdate: defaultComponentAdditions,
          };
        });
      }
    },
    sendClickEvent: () => {
      if (trackingService) {
        const linkType = trackingService.evaluateLinkType(appContent.linkUrl || '') || 'content';
        const clickEvent = {
          event: {
            attributes: {
              componentName: componentNameWithDashes,
              elementName: 'button',
              label: appContent.linkLabel,
              clickID: '',
              pos: '',
              targetURL: appContent.linkUrl || '',
              value: removeCurlyBraces(appContent.headline || ''),
            },
            eventInfo: {
              eventAction: linkType,
              eventName: `${componentNameWithSpaces} - cta click`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        trackingService.track(clickEvent);
      }
    },
  };
}

function removeCurlyBraces(str: string) {
  return str.replace(/{[^}]*}/g, '');
}
