import React from 'react';
import styled from 'styled-components';
import { SYS_SPACE_RELATIVE_3_XL, ThemeProvider } from '@oneaudi/unified-web-common';
import { AppContent } from '../../types';
import { useTracking } from '../../utils/useTracking';
import { BasicTeaserLike } from './BasicTeaserLike';

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.global.color.canvas.default};
  padding: ${SYS_SPACE_RELATIVE_3_XL} var(--page-margin);
`;

export const CountryTeaser: React.FC<AppContent> = (props) => {
  const { theme } = props;

  const ref = React.useRef(null);
  const tracking = useTracking(ref, props, {
    implementer: 37,
  });

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  return (
    <ThemeProvider theme={{ name: theme === 'Dark' ? 'dark' : 'light' }}>
      <StyledContainer ref={ref} data-test-id="country-teaser">
        <BasicTeaserLike {...props} onTeaserClick={tracking.sendClickEvent} />
      </StyledContainer>
    </ThemeProvider>
  );
};
