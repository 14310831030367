import React, { createContext, PropsWithChildren, useMemo } from 'react';

import { Logger } from '@feature-hub/logger';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { RenderModeServiceV1, RenderMode } from '@oneaudi/render-mode-service';

import { debug } from './utils/debugging';

interface FeatureAppEnv {
  featureAppId: string;
  featureAppName?: string;
}

interface ContextState {
  readonly featureAppEnv: FeatureAppEnv;
  readonly loggerService?: Logger;
  readonly trackingService?: TrackingServiceV2;
  readonly localeService?: LocaleServiceV1;
  readonly vueFormatterService?: VueFormatterServiceInterfaceV1;
}

export const Context = createContext<ContextState>({} as ContextState);

export interface ContextProviderProps {
  readonly featureAppEnv: FeatureAppEnv;
  readonly loggerService?: Logger;
  readonly trackingService?: TrackingServiceV2;
  readonly localeService?: LocaleServiceV1;
  readonly vueFormatterService?: VueFormatterServiceInterfaceV1;
  readonly renderModeService?: RenderModeServiceV1;
}

const ContextProvider = ({
  children,
  featureAppEnv,
  loggerService: logger,
  trackingService,
  localeService,
  vueFormatterService,
  renderModeService,
}: PropsWithChildren<ContextProviderProps>) => {
  const value = useMemo(() => {
    const renderMode =
      renderModeService && renderModeService.getRenderMode() === RenderMode.EDIT
        ? RenderMode.EDIT
        : RenderMode.DEFAULT;

    debug(
      `renderMode == ${renderMode} == ${renderMode === RenderMode.DEFAULT ? 'DEFAULT' : 'EDIT'}`,
    );

    return {
      featureAppEnv,
      loggerService: logger,
      trackingService,
      localeService,
      vueFormatterService,
      renderMode,
      isDefaultRenderMode: renderMode === RenderMode.DEFAULT,
      isEditRenderMode: renderMode === RenderMode.EDIT,
    };
  }, [renderModeService]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ContextProvider;
